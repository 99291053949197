import { ApiRequestError } from '@/composables/useApi'
import * as Sentry from '@sentry/vue'
import { setupLayouts } from 'virtual:generated-layouts'
import routes from 'virtual:generated-pages'
import { ViteSSG } from 'vite-ssg'
import { RouterView } from 'vue-router'
import { Token, User } from './store/auth'
import './style.css'
import { CustomModule } from './types'
import { hasRequestError } from './utils/guards'

interface AuthResponse {
  idToken: Token['id']
  refreshToken: Token['refresh']
  current_user: User
}

export const createApp = ViteSSG(
  RouterView,
  {
    routes: setupLayouts(routes),
    base: import.meta.env.BASE_URL,
  },
  (ctx) => {
    Object.values(
      import.meta.glob<{ install: CustomModule }>('./modules/*.ts', {
        eager: true,
      }),
    ).forEach((i) => i.install?.(ctx))

    if (ctx.isClient) {
      Sentry.init({
        app: ctx.app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(ctx.router),
            tracePropagationTargets: [/(protected|public)_api/],
          }),
        ],
        tracesSampleRate: import.meta.env.PROD ? 0.5 : 1.0,
      })
    }

    const authStore = useAuthStore()
    const queryStore = useQueryStore()

    ctx.router.beforeEach(async (from, _, next) => {
      const { auth_code, spot_id } = from.query

      if (typeof spot_id === 'string') {
        authStore.setSpotId(spot_id)
      }

      if (typeof auth_code === 'string') {
        const { data } = await useFetch(
          `${import.meta.env.VITE_API_URL}/auth/tokens`,
        )
          .post({
            auth_code,
            spot_id,
          })
          .json<AuthResponse | ApiRequestError>()

        if (hasRequestError<AuthResponse, ApiRequestError>(data.value)) {
          if (Array.isArray(data.value.errors)) {
            const [err] = data.value.errors

            const toast = useToast()

            toast.show({
              type: 'error',
              title: err.title,
              detail:
                'Authentication code has expired. Please reload this page to continue.',
            })
          }

          return next({
            path: 'unauthorized',
            query: { from: from.path },
          })
        }

        if (data.value) {
          authStore.setToken({
            id: data.value.idToken,
            refresh: data.value.refreshToken,
          })

          authStore.setUser(data.value.current_user)
        }
      }

      queryStore.initialize(from.query)

      return next()
    })
  },
)
