interface Alert {
  type?: 'success' | 'warning' | 'error' | 'info'
  title?: string
  detail: string
}

const [isShown, toggleShow] = useToggle(false)
const state: Alert = reactive({
  type: 'info',
  detail: 'Error detail - not defined',
})

export const useToast = () => {
  function show({ type = 'info', title, detail }: Alert) {
    state.type = type
    state.title = title
    state.detail = detail

    toggleShow(true)
  }

  function hide() {
    toggleShow(false)
  }

  return {
    isShown,
    state,
    show,
    hide,
  }
}
