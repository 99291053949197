import { isNumber, isStringifiedBoolean } from '@/utils/guards'
import {
  castStringifiedBoolean,
  transformKeysFromCamelToSnakeCase,
} from '@/utils/parsers'
import { defineStore } from 'pinia'
import { LocationQueryRaw } from 'vue-router'

type Params =
  | 'spot_id'
  | 'external_product_id'
  | 'product_id'
  | 'serie_id'
  | 'order_id'
  | 'tab_id'
  | 'page_id'
  | 'uid'

type ValidParams = {
  [P in Params]?: P extends `${string}_id` ? number : string
} & Record<string, unknown>

export const useQueryStore = defineStore('query', () => {
  const props: Ref<ValidParams> = ref({})
  const sessionStorage = useSessionStorage<ValidParams>('query', {})

  function initialize(query: LocationQueryRaw) {
    const normalizedQuery = transformKeysFromCamelToSnakeCase(query)

    for (const [key, value] of Object.entries(normalizedQuery)) {
      if (typeof value !== 'string') return
      updateProp(key, value)
    }
  }

  function updateProp(key: string, value: unknown) {
    if (isStringifiedBoolean(value)) {
      value = castStringifiedBoolean(value)
    } else if (isNumber(value)) {
      value = Number(value)
    }

    props.value[key] = value
    sessionStorage.value[key] = value
  }

  return {
    initialize,
    updateProp,
    props: sessionStorage,
  }
})
