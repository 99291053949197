import { ApiRequestError } from '@/composables/useApi'

export const hasRequestError = <Ok, Err extends ApiRequestError>(
  data: Ok | Err | null,
): data is Err => {
  return !!data && typeof data === 'object' && 'errors' in data
}

export function isNumber(value: unknown): value is number {
  return (
    (typeof value === 'string' || typeof value === 'number') &&
    !isNaN(Number(value))
  )
}

export function isStringifiedBoolean(
  value: unknown,
): value is 'true' | 'false' {
  return (
    typeof value === 'string' && ['true', 'false'].includes(value.toLowerCase())
  )
}
