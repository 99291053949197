const __pages_import_0__ = () => import("/src/pages/tickets/redeem.vue");
const __pages_import_1__ = () => import("/src/pages/tickets/orders.vue");
const __pages_import_2__ = () => import("/src/pages/tickets/index.vue");
const __pages_import_3__ = () => import("/src/pages/checkout/success.vue");
const __pages_import_4__ = () => import("/src/pages/checkout/index.vue");
const __pages_import_5__ = () => import("/src/pages/unauthorized.vue");
import __pages_import_6__ from "/src/pages/index.vue";
const __pages_import_7__ = () => import("/src/pages/[...all].vue");

const routes = [{"name":"tickets-redeem","path":"/tickets/redeem","component":__pages_import_0__,"props":true},{"name":"tickets-orders","path":"/tickets/orders","component":__pages_import_1__,"props":true},{"name":"tickets","path":"/tickets","component":__pages_import_2__,"props":true},{"name":"checkout-success","path":"/checkout/success","component":__pages_import_3__,"props":true,"meta":{"layout":"order"}},{"name":"checkout","path":"/checkout","component":__pages_import_4__,"props":true,"meta":{"layout":"order"}},{"name":"unauthorized","path":"/unauthorized","component":__pages_import_5__,"props":true},{"name":"index","path":"/","component":__pages_import_6__,"props":true},{"name":"all","path":"/:all(.*)*","component":__pages_import_7__,"props":true}];

export default routes;