import { defineStore } from 'pinia'
import { Ref } from 'vue'

export interface Token {
  id: string
  refresh: string
}

export type Role = 'admin' | 'owner' | 'user'
export interface User {
  email: string
  spotId: string
  role: Role
}

export const useAuthStore = defineStore('auth', () => {
  const spotId = ref('')
  const token: Ref<Token | undefined> = ref()
  const user: Ref<User | undefined> = ref()

  function setSpotId(value: string) {
    spotId.value = value
  }

  function setToken(value: Token) {
    token.value = value
  }

  function setUser(value: User) {
    user.value = value
  }

  return {
    spotId,
    setSpotId,
    token,
    setToken,
    user,
    setUser,
  }
})
