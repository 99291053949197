import { LocationQueryValue } from 'vue-router'

type QueryValue = LocationQueryValue | LocationQueryValue[]

export const parseNumericQueryParam = (param: QueryValue) => {
  if (typeof param !== 'string') return

  const castValue = Number(param)

  if (isNaN(castValue)) return

  return castValue
}

export function castStringifiedBoolean(value: 'true' | 'false') {
  return value === 'true' ? true : false
}

export function transformKeysFromCamelToSnakeCase(
  props: Record<string, unknown>,
) {
  return Object.entries(props).reduce((accumulator, [key, value]) => {
    key = key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

    accumulator[key] = value
    return accumulator
  }, {} as Record<string, unknown>)
}
